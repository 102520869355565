<template>
  <base-info-card title="About OlapDB">
    <router-link
      v-for="feature in getFeatures"
      :key="feature.title"
      :text="feature.title"
      class="mb-8 grey--text body-1 d-block text-none"
      :to="feature.href"
      v-html="`> ${feature.title}`"
    />
  </base-info-card>
</template>

<script>
  export default {
    name: 'SectionFeaturesAlt',

    computed: {
      getFeatures () {
        return [
          {
            title: this.$t('introduction'),
            href: 'index.html#welcome',
          },
          {
            title: this.$t('chooseTitle'),
            href: 'index.html#why-choose-us',
          },
          {
            title: this.$t('featureTitle'),
            href: 'index.html#powerful-features',
          },
          {
            title: this.$t('componentTitle'),
            href: 'index.html#service-and-components',
          },
          {
            title: this.$t('startTitle'),
            href: 'index.html#how-to-start',
          },
        ]
      },
    },
  }
</script>
